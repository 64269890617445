<template>
  <div class="page">
    <Header :loginState="loginState" />
    <div class="account-page">
      <left-menu />
      <div class="account-center">
        <div class="center-box account-information">
          <account-nav />
          <div class="title-box">           
            <div class="cfx"></div>
            <p>{{$t('accountCenter.homeTitle1')}}</p>
          </div>
          <div class="account-data">
            <div class="account-box">
              <div class="user-img-box">
                <img v-if="!findUserInfo.avatar" class="user-img" src="@/assets/images/account/user-logo.png" alt="" />
                <img v-else class="user-img" :src="findUserInfo.avatar" alt="" />
                <input @change="uploadAvatar" name="file" type="file" accept="image/png,image/gif,image/jpeg" id="male" style="display: none;"/>
                <label for="male" class="user-btn">{{$t('accountInfor.modifyUserHead')}}</label>
              </div>            
              <div class="data-list-box">
                <div class="data-list">
                  <p class="p1">{{$t('accountInfor.nickname')}}</p>
                  <p class="p2"><span>{{findUserInfo.nickName}}</span><img @click="editNameShow" src="@/assets/images/account/edit-h-icon.png" alt=""></p>                 
                </div>
                <div class="data-list">
                  <p class="p1">{{$t('accountInfor.realName')}}</p>
                  <p v-if="findUserInfo.bankAttestResult==-1 || findUserInfo.bankAttestResult==0" class="p2 p2-s"><span>{{$t('accountInfor.notCertified')}}</span><a @click="perfectData" href="javascript:;"><img src="@/assets/images/account/edit-h-icon.png" alt=""></a></p>
                  <p v-if="findUserInfo.bankAttestResult==2" class="p2"><span>{{$t('accountInfor.underReview')}}</span></p>
                  <p v-if="findUserInfo.bankAttestResult==1" class="p2"><span>{{findUserInfo.name}}</span></p>                          
                </div>
                <div class="data-list">
                  <p class="p1">{{$t('accountCenter.email')}}</p>
                  <p class="p2">
                    <span v-if="findUserInfo.emailIsVer == 1">{{findUserInfo.email}}</span>
                    <span v-if="findUserInfo.emailIsVer == 0">--</span>
                    <a href="./mailbox"><img src="@/assets/images/account/edit-h-icon.png" alt=""></a>
                  </p>            
                </div>
                <div class="data-list">
                  <p class="p1">{{$t('accountCenter.phoneNumber')}}</p>
                  <p class="p2">
                    <span v-if="findUserInfo.mobileIsVer == 1">{{findUserInfo.mobile}}</span>
                    <span v-if="findUserInfo.mobileIsVer == 0">--</span>
                    <a href="./phoneNumber"><img src="@/assets/images/account/edit-h-icon.png" alt=""></a>
                  </p>                  
                </div>
                <div class="data-list">
                  <p class="p1">{{$t('accountCenter.residentialArea')}}</p>
                  <p v-if="countryCurrent == '' || countryCurrent == null || countryCurrent == undefined" class="p2">--</p>
                  <p v-else class="p2">{{countryCurrent.displayName}}</p>
                </div>
              </div>
              <!-- 修改昵称弹窗 -->
              <div class="name-data-modal">
                <div class="mask" v-if="showModal" @click="editNameHide"></div>
                <div class="pop" v-if="showModal">
                  <div class="data-box">
                    <img @click="editNameHide" class="close-btn" src="@/assets/images/account/close-min.png" alt="">
                    <div class="name-box">
                      <img src="@/assets/images/account/xgnc-img.png" alt="">
                      <p class="tlt">{{$t('accountInfor.modifyNickname')}}</p>
                      <div class="input-hz">
                        <input v-model="nicknameVal" @Blur="inputBlur" type="text" :placeholder="$t('accountInfor.inputNickname')" maxlength="22">
                        <p v-if="errShowList.errShow" class="err-text">
                        <img src="../assets/images/register/dx-icon.png" alt="" />
                        <span>{{errShowList.errContent}}</span>
                      </p>
                      </div>                                      
                      <button @click="submitNickname">{{$t('accountInfor.Submit')}}</button>    
                    </div>                     
                  </div>     
                </div>          
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import AccountNav from '@/components/AccountNav.vue';
import { getCookie } from '@/util/cookie';
import account from '@/api/account.js';
import {HEADER_USER_TOKEN} from '@/util/const.js'
export default {
  name: "PersonalData",
  components: {
    Header,
    LeftMenu,
    AccountNav
  },
  data() {
    return {
      loginState:2,
      showModal:false,
      findUserInfo:[],
      countryList: {
        countrys: [],
        countryActive:'',
      },
      countryCurrent:[],
      nicknameVal:'',
      errShowList: {
        errShow:false,
        errContent:'',
      },
    }
  },
  created() {

    // 查询用户基本资料
    const userToken = getCookie(HEADER_USER_TOKEN);
    console.log(userToken);
    if(!userToken){
      this.$router.push({ path: '/login' })
    }else{
      account.findUserInfo().then((res)=>{
        if(res.data.success == 1){
              this.findUserInfo = res.data.value
               // 获取邮箱注册的国家下拉列表数据
              account.getSupportCountry().then((result)=>{
                this.countryList.countrys = result.data.value
                let countryCurrent = this.countryList.countrys.filter(it => it.countryId == res.data.value.countryId);
                this.countryCurrent = countryCurrent[0];
              }).catch(err=>{
                console.log(err);
              })
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    }
  },
  methods: {
    // 修改头像
    uploadAvatar(e){
      var self = this
      let file = e.target.files[0]
      let param = new FormData(); // 创建form对象
      param.append('file', file, file.name) // 通过append向form对象添加数据
      account.uploadAvatar(param).then((res)=>{
          if(res.data.success == 1){
              location.reload();
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    },

    // 点击完善资料
    perfectData(){
      var dataType = "";
      if(this.findUserInfo.emailIsVer == 0){
          dataType = "mobileRegData"
      }else if(this.findUserInfo.mobileIsVer == 0){
          dataType = "emailRegData"
      }else{
        dataType = "emailRegData"
      }
      const that = this;
      that.$router.push({
        path: '/authentication',
        query: {         
          dataType:dataType,
        }
        
      });
    },

    // 显示修改昵称弹窗
    editNameShow(){
      this.showModal = true
    },
    // 关闭修改昵称弹窗
    editNameHide(){
      this.showModal = false
    },
    // 修改昵称输入框失去焦点校验
    inputBlur(){
      if(!this.nicknameVal){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt22'); 
      }else{
        this.errShowList.errShow = false;
      }
    },
    // 提交修改昵称
    submitNickname(){
      if(!this.nicknameVal){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt22'); 
        return;
      }
       let data = {
        nickname: this.nicknameVal,
      }
      account.updateNickname(data).then((res)=>{
        if(res.data.success == 1){
          this.showModal = false;
          location.reload();
        }else{
          this.errShowList.errShow = true;
          this.errShowList.errContent = res.data.message; 
        }
      }).catch(err=>{
        this.errShowList.errShow = true;
        this.errShowList.errContent = err.message;
        console.log(err.message);
      })
    }
    
  },
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
